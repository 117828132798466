import "./blog-short.style.scss";
import {BlogShortProps} from "./blog-short.type";
import IdImage from "../../../base/id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";
import {Link} from "react-router-dom";
import Chip from "../../../base/chip/chip.component";
import {translateBlogTag} from "../../../../enums/blog.enum";
import {dateString} from "../../../../services/date.service";

const BlogShort = (props: BlogShortProps) => {

  return (
      <Link to={"/news/" + props.blogShort.id}>
        <div className="blog-short">
          <div className="blog-short__head">
            <div className="blog-short__head-chips">
              {
                props.blogShort.tags.slice(0, 2).map((blogTag, key) =>
                    <Chip key={key}>{translateBlogTag(blogTag.blogTag)}</Chip>)
              }
            </div>
            <IdImage id={props.blogShort.imageId} size={ImageSize.LANDSCAPE_SMALL}/>
          </div>
          <div className="blog-short__body">
            <h4>{props.blogShort.title}</h4>
            <small>{dateString(props.blogShort.datePublished)} | {props.blogShort.author}</small>
            <p>{props.blogShort.description}</p>
          </div>
        </div>
      </Link>
  )
}

export default BlogShort;
