import LayoutSequence from "../layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../layout/sequence/layout-sequence.type";
import Icon from "../icon/icon.component";
import {PaginationProps} from "./pagination.type";
import "./pagination.styles.scss";
import {Size} from "../../../enums/size.enum";
import {useState} from "react";
import useWindowDimensions from "../../../hooks/window-dimension.hook";

const Pagination = (props: PaginationProps) => {
    const [activePage, setActivePage] = useState<number>(1);
    const pageCount = Math.ceil(props.total / props.size);
    const {width} = useWindowDimensions();

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber !== activePage) {
            props.setStart((pageNumber - 1) * props.size);
            setActivePage(pageNumber);
        }
    };

    const previousPage = () => handlePageChange(activePage > 1 ? activePage - 1 : pageCount);
    const nextPage = () => handlePageChange(activePage < pageCount ? activePage + 1 : 1);

    const renderPageNumbers = (start: number, end: number): JSX.Element[] => {
        return Array.from({length: end - start + 1}, (_, i) => start + i).map((pageNumber) => (
            <div
                className={`pagination__item${activePage === pageNumber ? " active" : ""}`}
                onClick={() => handlePageChange(pageNumber)}
                key={pageNumber}
            >
                {pageNumber}
            </div>
        ));
    };

    const renderBreakLabel = () => <p className="break_label">...</p>;

    const renderPagination = () => {
        if (pageCount <= 7) {
            return renderPageNumbers(1, pageCount);
        }

        const isAtStart = activePage <= 4;
        const isAtEnd = activePage > pageCount - 4;
        const isAtMiddle = !isAtStart && !isAtEnd;

        return (
            <>
                {renderPageNumbers(1, 1)}
                {isAtStart ? renderPageNumbers(2, 5) : renderBreakLabel()}
                {isAtMiddle && renderPageNumbers(activePage - 1, activePage + 1)}
                {isAtEnd ? renderPageNumbers(pageCount - 4, pageCount - 1) : renderBreakLabel()}
                {renderPageNumbers(pageCount, pageCount)}
            </>
        );
    };

    const renderMobilePagination = () => {
        if (pageCount <= 5) {
            return renderPageNumbers(1, pageCount);
        }

        const isAtStart = activePage <= 3;
        const isAtEnd = activePage > pageCount - 3;
        const isAtMiddle = !isAtStart && !isAtEnd;

        return (
            <>
                {renderPageNumbers(1, 1)}
                {isAtStart ? renderPageNumbers(2, 3) : renderBreakLabel()}
                {isAtMiddle && renderPageNumbers(activePage, activePage)}
                {isAtEnd ? renderPageNumbers(pageCount - 2, pageCount - 1) : renderBreakLabel()}
                {renderPageNumbers(pageCount, pageCount)}
            </>
        );
    };

    if (pageCount <= 1) {
        return null; // Don't render anything if there's only one page or none
    }

    return (
        <div className="pagination">
            <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <div className="pagination__icon" onClick={previousPage}>
                        <Icon name={"navigate_before"} size={Size.L}/>
                    </div>
                    {width < 600 ? renderMobilePagination() : renderPagination()}
                    <div className="pagination__icon" onClick={nextPage}>
                        <Icon name={"navigate_next"} size={Size.L}/>
                    </div>
            </LayoutSequence>
        </div>
    );
};

export default Pagination;
