import {imageProps} from "./image.type";
import "./image.style.scss"

const Image = (props: imageProps) => {
    const handleClick = () => {
        if (props.isClickable) {
            window.open(props.src, '_blank');
        }
    };

    return (
        <img
            className={"image" + (props.isClickable ? " clickable" : "")}
            src={props.src}
            alt={props.alt}
            title={props.alt}
            onClick={handleClick}/>
    );
}

export default Image;
