import "./training-places.style.scss"
import {Color} from "../../enums/color.enum";
import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Button from "../../components/base/button/button.component";
import {ButtonType} from "../../components/base/button/button.type";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {useNavigate} from "react-router-dom";
import LayoutGrid from "../../components/base/layout/grid/layout-grid.component";
import schnuppern1 from "../../images/schnuppern/schnuppern1.jpg";
import schnuppern2 from "../../images/schnuppern/schnuppern2.jpg";
import schnuppern3 from "../../images/schnuppern/schnuppern3.jpg";
import schnuppern4 from "../../images/schnuppern/schnuppern4.jpg";
import imageEDB from "../../images/profession/EDB.jpg";
import imageFK from "../../images/profession/FK.jpg";
import imageIA from "../../images/profession/IA.jpg";
import imageIP from "../../images/profession/IP.jpg";
import Image from "../../components/base/image/image.component";
import Carousel from "../../components/base/carousel/carousel.component";
import applyImage from "../../images/icons/apply.png";

const TrainingPlacesPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Ausbildungsplätze</h1>
                <h5>
                    Wir schreiben ab Anfangs Juli (vor den Sommerferien) jeweils unsere knapp 30 neuen Lehrstellen aus.
                    Mit der offiziellen Selektion aller Bewerber und Bewerbungen, beginnen wir ab Mitte August. Du musst
                    dich also ein wenig gedulden, falls du bereits bei den ersten Bewerbern bist.
                </h5>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h2>Online-Ausschreibungen</h2>
                <div className="apply-container">
                    <Image src={applyImage} alt="Online-Auschreibung"/>
                    <h5>
                        Entdecke spannende Möglichkeiten für deinen Berufseinstieg! Bewirb dich jetzt auf eine
                        unserer Lehrstellen und starte deine Karriere bei BRACK.CH. Klicke hier, um unsere aktuellen
                        Online-Ausschreibungen anzusehen und dich zu bewerben.
                    </h5>
                </div>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ausbildungsplaetze/online-ausschreibungen")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Lehr- und Praktikumstellen</h2>
                <div className="vacancies-container">
                    <h5>
                        Entdecke alle aktuellen Lehr- und Praktikumsstellen in unserem Unternehmen. Hier findest du
                        eine Übersicht über alle verfügbaren Lehrberufen sowie Informationen darüber, wie viele
                        Stellen noch frei sind. Erfahre, welche Möglichkeiten wir für dich bereithalten.
                    </h5>
                    <Carousel items={[
                        <Image src={imageEDB} alt="Entwickler*in digitales Business EFZ" isClickable={true}/>,
                        <Image src={imageFK} alt="Fachmann/-frau Kundendialog EFZ" isClickable={true}/>,
                        <Image src={imageIA} alt="Informatiker*in Applikationsentwicklung EFZ" isClickable={true}/>,
                        <Image src={imageIP} alt="Informatiker*in Plattformentwicklung EFZ" isClickable={true}/>
                    ]}/>
                </div>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ausbildungsplaetze/lehr-und-praktikumstellen")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h2>Bewerbungsinformationen</h2>
                <h5>
                    Möchtest du mehr darüber erfahren, wie du dich bei uns bewerben kannst und was dich während des
                    Bewerbungsprozesses erwartet? Auf unserer Bewerbungsinfo-Seite findest du alle Informationen, die
                    du brauchst, um den Weg zu deiner Lehrstelle bei uns zu finden. Von Tipps zum Schnuppern bis hin zu
                    Checklisten für dein Bewerbungsdossier – hier erhältst du alle wichtigen Infos rund ums Bewerben.
                </h5>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ausbildungsplaetze/bewerbungsinformationen")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Schnuppern bei BRACK.CH</h2>
                <h5>
                    Möchtest du deinen Wunschberuf und unser Unternehmen vorab kennenlernen? Erfahre mehr über unsere
                    Schnuppermöglichkeiten und was dich dabei erwartet.
                </h5>
                <LayoutGrid desktop={4} tablet={4} mobile={2}>
                    <Image src={schnuppern1} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern2} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern3} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern4} alt="Schnuppern" isClickable={true}/>
                </LayoutGrid>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ausbildungsplaetze/schnuppern-bei-brack")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>
        </>
    )
}

export default TrainingPlacesPage;
