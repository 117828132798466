import "./dynamic.style.scss";
import {DynamicProps} from "./dynamic.type";
import {useEffect, useState} from "react";
import {apiRequestJson} from "../../../services/api.service";
import {Method} from "../../../enums/api.enum";
import {
  DynamicButtonDTO,
  DynamicDTO,
  DynamicGridDTO,
  DynamicImageDTO,
  DynamicSpacerDTO,
  DynamicTextDTO
} from "../../../dtos/dynamic.dto";
import Spinner from "../spinner/spinner.component";
import {DynamicComponent} from "../../../enums/dynamic-component.enum";
import DynamicButton from "./button/dynamic-button.component";
import DynamicGrid from "./grid/dynamic-grid.component";
import DynamicText from "./text/dynamic-text.component";
import DynamicSpacer from "./spacer/dynamic-spacer.component";
import DynamicImage from "./image/dynamic-image.component";

const Dynamic = (props: DynamicProps) => {
  const [dynamic, setDynamic] = useState<Array<DynamicDTO> | null>(props.dynamic !== undefined ? props.dynamic : null)

  useEffect(() => {
    if (dynamic === null) {
      apiRequestJson<Array<DynamicDTO>>("v3/dynamic/" + props.id, Method.GET, null, null)
      .then(dto => setDynamic(dto))
      .catch(() => setDynamic(null));
    }
  }, [dynamic, props.id])

  return (
      <>
        {
          dynamic !== null ? (
              dynamic.map((dynamicComponent, key) => {
                switch (dynamicComponent.component) {
                  case DynamicComponent.GRID:
                    return <DynamicGrid dynamicComponent={dynamicComponent as DynamicGridDTO} key={key}/>;
                  case DynamicComponent.TEXT:
                    return <DynamicText dynamicComponent={dynamicComponent as DynamicTextDTO} key={key}/>;
                  case DynamicComponent.SPACER:
                    return <DynamicSpacer dynamicComponent={dynamicComponent as DynamicSpacerDTO} key={key}/>;
                  case DynamicComponent.IMAGE:
                    return <DynamicImage dynamicComponent={dynamicComponent as DynamicImageDTO} key={key}/>;
                  case DynamicComponent.BUTTON:
                    return <DynamicButton dynamicComponent={dynamicComponent as DynamicButtonDTO} key={key}/>
                  default:
                    return <p>Hello</p>
                }
              })
          ) : (
              <Spinner/>
          )
        }
      </>
  )
}

export default Dynamic;
