import {ChipProps} from "./chip.type";
import "./chip.styles.scss";

const Chip = (props: ChipProps) => {
  return (
      <>
        <div className="chip"
             onClick={() => props.action !== undefined ? props.action() : {}}>
          {props.children}
        </div>
      </>
  )
}

export default Chip;
