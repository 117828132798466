import LayoutArticle from "../../components/base/layout/article/layout-article.component";
import {useEffect, useState} from "react";
import {apiRequestJson} from "../../services/api.service";
import {BlogDTO} from "../../dtos/blog.dto";
import {Method} from "../../enums/api.enum";
import {useParams} from "react-router-dom";
import {dateString} from "../../services/date.service";
import Chip from "../../components/base/chip/chip.component";
import {translateBlogTag} from "../../enums/blog.enum";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Spinner from "../../components/base/spinner/spinner.component";
import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import Dynamic from "../../components/base/dynamic/dynamic.component";

const BlogPage = () => {
  const {id} = useParams()
  const [blog, setBlog] = useState<BlogDTO | null>(null)

  useEffect(() => {
    apiRequestJson<BlogDTO>("v3/blog/" + id, Method.GET, null, null)
    .then(dto => setBlog(dto))
    .catch(() => setBlog(null));
  }, [id])

  return (
      <>
        {
          blog !== null ? (
              <>
                <LayoutArticle imageId={blog.imageId}
                               title={blog.title}
                               subTilte={dateString(blog.datePublished) + " | " + blog.author}>
                  <LayoutSequence direction={LayoutSequenceDirection.COLUMN}>
                    <LayoutSequence direction={LayoutSequenceDirection.ROW}>
                      {
                        blog.tags.map((blogTag, key) =>
                            <Chip key={key}>
                              {translateBlogTag(blogTag.blogTag)}
                            </Chip>)
                      }
                    </LayoutSequence>
                    <h3>{blog.description}</h3>
                  </LayoutSequence>
                </LayoutArticle>
                <LayoutSection color={Color.BASE_INVERTED}>
                  <Dynamic id={blog.dynamicComponentId}/>
                </LayoutSection>
              </>
          ) : (
              <Spinner/>
          )
        }
      </>
  )
}

export default BlogPage;
