import "./header.style.scss";
import logoBerufsbildung from "../../../images/logos/berufsbildung.png";
import Nav from "../nav/nav.component";
import {NavLink} from "react-router-dom";

const Header = () => {

  return (
      <div className="header__container">
        <header className="header">
          <>
            <NavLink to="/"
                     className="header__logo-link">

              <img className="header__logo"
                   src={logoBerufsbildung}
                   alt="Brack Berufsbildung Logo"/>
            </NavLink>
            <Nav/>
          </>
        </header>
      </div>
  )
}

export default Header;
