import "./about-us-competec.style.scss";
import Image from "../../../base/image/image.component";
import logosCompetecGroup from "../../../../images/logos/all_logos_white_bg.png";
import LayoutSequence from "../../../base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../base/layout/sequence/layout-sequence.type";
import Button from "../../../base/button/button.component";
import {Color} from "../../../../enums/color.enum";
import {ButtonType} from "../../../base/button/button.type";
import {navigateExternal} from "../../../../services/navigation.service";

const AboutUsCompetec = () => {

    return (
        <div className="about-us-competec">
            <Image src={logosCompetecGroup} alt="Logos Competec Gruppe"/>
            <h5>
                Aus der 1994 gegründeten Brack Consulting, wurde 2007 zusammen mit dem Handelsunternehmen Alltron AG die
                Competec Holding gegründet. Die heutige Competec-Gruppe besteht aus verschiedenen Firmen
                (Brands/Marken), an 8 verschiedenen Standorten und beschäftigt über 1'300 Mitarbeitende. Im Jahr 2023
                hat die Gruppe einen Umsatz von rund 1,14 Milliarden CHF erzielt.
            </h5>
            <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                <Button
                    color={Color.PRIMARY}
                    type={ButtonType.OUTLINED}
                    action={() => navigateExternal("https://www.competec.ch/ueber-uns/", true)}>
                    Mehr erfahren
                </Button>
            </LayoutSequence>
        </div>
    )
}

export default AboutUsCompetec;
