import {DynamicImageProps} from "./dynamic-image.type";
import IdImage from "../../id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";

const DynamicImage = (props: DynamicImageProps) => {

  return (
      <>
        <IdImage id={props.dynamicComponent.imageId} size={ImageSize.LANDSCAPE_MEDIUM}/>
      </>
  )
}

export default DynamicImage
