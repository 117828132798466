import {idImageProps} from "./id-image.type";
import "./id-image.style.scss"
import {CSSProperties, useEffect, useState} from "react";
import {apiRequest} from "../../../services/api.service";
import {Method} from "../../../enums/api.enum";
import {RequestParam} from "../../../dtos/api.dto";
import {ImageSize} from "../../../enums/image-size.enum";
import {randomNumber} from "../../../services/random.service";

const IdImage = (props: idImageProps) => {

  const [image, setImage] = useState<Blob | undefined>()
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    let size = props.size;

    if (window.screen.width < 800) {
      switch (size) {
        case ImageSize.LANDSCAPE_LARGE:
          size = ImageSize.LANDSCAPE_MEDIUM;
          break;
        case ImageSize.LANDSCAPE_MEDIUM:
          size = ImageSize.LANDSCAPE_SMALL;
          break;
      }
    }

    const params: Array<RequestParam> = [
      {key: "size", value: size}
    ]

    apiRequest("v3/image/" + props.id, Method.GET, params, null)
    .then(response => {
      const headers = response.headers;

      const headerDescription = headers.get("description");
          if (headerDescription !== null) {
            setDescription(headerDescription);
          }

          response.blob().then(blob => setImage(blob));
        }
    )
  }, [props.id, props.size])

  return (
      <>
        {
          image !== undefined ? (
              <img className="id-image"
                   src={URL.createObjectURL(image)}
                   alt={description}
                   title={description}/>
          ) : (
              <div className="id-image__placeholder"
                   style={{"--id-image__placeholder-delay": "-" + randomNumber(5000) + "ms"} as CSSProperties}></div>
          )
        }
      </>
  );
}

export default IdImage;
