import "./about-us.style.scss"
import {Color} from "../../enums/color.enum";
import LayoutSection from "../../components/base/layout/section/layout-section.component";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Button from "../../components/base/button/button.component";
import {ButtonType} from "../../components/base/button/button.type";
import {useNavigate} from "react-router-dom";
import AboutUsMarktplatz from "../../components/complex/about-us/marktplatz/about-us-marktplatz.component";
import TestimonialPreview from "../../components/complex/testimonial/preview/testimonial-preview.component";
import LayoutGrid from "../../components/base/layout/grid/layout-grid.component";
import Image from "../../components/base/image/image.component";
import logoTop from "../../images/logos/top.png";
import logoIctAward from "../../images/logos/ict-award.jpg";
import LayoutSummary from "../../components/base/layout/summary/layout-summary.component";
import lernAcademyOrganisation from "../../images/static/lern-academy_organisation.jpg";
import visionIcon from "../../images/icons/vision.png";
import missionIcon from "../../images/icons/mission.png";
import valueIcon from "../../images/icons/value.png";

const AboutUsPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Über uns</h1>
                <h5>
                    BRACK.CH Berufsbildung (ehemalig stiftipedia.ch) ist die neue Plattform für das eigene Bildungswesen
                    der Competec-Gruppe. Hier findest du eine grosse Auswahl an Informationen über verschiedene
                    Berufsbilder, über unsere Lehrberufe, über unsere offenen Lehrstellen, unsere internen und externen
                    Veranstaltungen, über unser internes Selektionsverfahren und unser neues und dynamische
                    Ausbildungskonzept "Marktplatz". Der Auftritt wurde von Grund auf von Lernenden der Competec-Gruppe
                    entwickelt, verwaltet und wird auch von ihnen gepflegt.
                </h5>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h2>Marktplatz</h2>
                <h5>
                    Die Ausbildung dürfen die Lernenden bei uns selbst gestalten. Der Marktplatz ist eine Plattform, auf
                    der Projekte (von 1 Monat bis 6 Monate) ausgeschrieben werden, auf die sich die Lernenden bewerben.
                    Dieser Marktplatz wurde von den Lernenden in der LernAcademy umgesetzt.
                </h5>
                <div className="graphic-container" onClick={() => navigate("/ueber-uns/marktplatz")}>
                    <AboutUsMarktplatz/>
                </div>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ueber-uns/marktplatz")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Vision and Mission</h2>
                <h5>
                    Als eines der führenden ICT-Ausbildungsunternehmen im Kanton AG engagieren wir uns stark, und
                    nachhaltig für die Jugend, unseren Nachwuchs und die späteren ICT-Fachkräfte. Wir entwickeln sie zu
                    qualifizierten, verantwortungsvollen Berufsleuten mit Perspektiven.
                </h5>
                <div className="vision-and-mission">
                    <div className="vision-and-mission__icons">
                        <div className="icon" onClick={() => navigate("/ueber-uns/vision#vision")}>
                            <Image src={visionIcon} alt="Vision"/>
                        </div>
                        <span className="gap"></span>
                        <div className="icon" onClick={() => navigate("/ueber-uns/vision#mission")}>
                            <Image src={missionIcon} alt="Mission"/>
                        </div>
                        <span className="gap"></span>
                        <div className="icon" onClick={() => navigate("/ueber-uns/vision#werte")}>
                            <Image src={valueIcon} alt="Werte"/>
                        </div>
                    </div>
                </div>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ueber-uns/vision")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h2>Interviews mit unseren Lernenden</h2>
                <TestimonialPreview/>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ueber-uns/testimonials")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>LernAcademy</h2>
                <h5>
                    Ab diesem Sommer werden wir mit unserer heutigen Berufsbildung neu als "LernAcademy" auftreten.
                    Unter diesem neuen Begriff verstehen wir unser komplettes Bildungswesen mit den heute knapp 80
                    Lernenden an 3 verschiedenen Standorten. Das heutige Junior-Center und die IT-Academy wird in die
                    neue "Lernwerkstatt" (praktische Spielwiese für unsere Lernenden) integriert. Räumlich gesehen,
                    rutschen wir alle etwas näher zusammen. Zukünftige Berufsinfotage, Schnuppertage oder unsere eigene
                    Bildungsmesse werden zukünftig in unseren "LernAcademy" Räumlichkeiten im 1. OG des Gewerbeparks
                    Mägenwil durchgeführt. Wir freuen uns auf euch!
                </h5>
                <div className="graphic-container">
                    <Image src={lernAcademyOrganisation} alt="LernAcademy" isClickable={true}/>
                </div>
                <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                    <Button
                        color={Color.PRIMARY}
                        type={ButtonType.OUTLINED}
                        action={() => navigate("/ueber-uns/lernacademy")}>
                        Mehr erfahren
                    </Button>
                </LayoutSequence>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <LayoutSummary elements={[
                    {
                        title: "Keine fixen Ausbildungspläne mehr",
                        children: (
                            <>
                                <h3>Keine fixen Ausbildungspläne mehr</h3>
                                <p>
                                    Das Marktplatz-Konzept ermöglicht jedem Lernenden selber zu entscheiden, welche
                                    Abteilung er/sie besuchen möchte.
                                </p>
                            </>
                        )
                    },
                    {
                        title: "Keine reine Bringschuld mehr",
                        children: (
                            <>
                                <h3>Keine reine Bringschuld mehr</h3>
                                <p>
                                    Mitdenken, Vorausschauen und zusätzlich benötige Informationen oder Angaben selbst
                                    beschaffen oder einholen. Nicht einfach abwarten und "Tee trinken"
                                </p>
                            </>
                        )
                    },
                    {
                        title: "Erlebtes weiterentwickeln dürfen",
                        children: (
                            <>
                                <h3>Erlebtes weiterentwickeln dürfen</h3>
                                <p>
                                    Das Ziel darf nicht das Ende bedeuten, es kommen laufend Anpassungen oder
                                    Erweiterungen dazu. Es muss neu geplant, angepasst oder realisiert werden. Aus
                                    Fehlern sollte man lernen
                                </p>
                            </>
                        )
                    },
                    {
                        title: "Eigenverantwortung übernehmen",
                        children: (
                            <>
                                <h3>Eigenverantwortung übernehmen</h3>
                                <p>
                                    Die Verantwortung für sein eigenes Tun zu übernehmen und von den daraus gewonnenen
                                    Erkenntnissen wieder dazulernen. Geradestehen für das, was man gemacht hat.
                                </p>
                            </>
                        )
                    },
                    {
                        title: "Selbständiges Arbeiten an echten Projekten",
                        children: (
                            <>
                                <h3>Selbständiges Arbeiten an echten Projekten</h3>
                                <p>
                                    Fähigkeit, Arbeitsaufgaben autonom zu bewältigen, sich am Arbeitsplatz selbst Ziele
                                    zu stecken und Handlungsnotwendigkeiten und Probleme von sich aus zu erfassen und
                                    zu lösen. Erfolgserlebnisse an produktiven Arbeiten
                                </p>
                            </>
                        )
                    },
                    {
                        title: "Mehr Teamfähigkeit",
                        children: (
                            <>
                                <h3>Mehr Teamfähigkeit</h3>
                                <p>
                                    Je mehr du dich selbst und deine Arbeit im Team einordnen kannst, desto mehr kannst
                                    du auch eigene Ideen und Ansätze überdenken und von der Gemeinschaft lernen.
                                    Gemeinsam ist man stärker
                                </p>
                            </>
                        )
                    }
                ]} color={Color.BASE}/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>Auszeichnungen</h2>
                <LayoutGrid desktop={2} tablet={2} mobile={1}>
                    <Image
                        src={logoIctAward}
                        alt={"ICT education & training award"}
                        isClickable={true}/>
                    <Image
                        src={logoTop}
                        alt={"TOP Ausbildungsort"}
                        isClickable={true}/>
                </LayoutGrid>
            </LayoutSection>
        </>
    )
}

export default AboutUsPage;
