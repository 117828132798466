import "./layout-grid.style.scss";
import {LayoutGridProps} from "./layout-grid.type";
import React from "react";

const LayoutGrid = (props: LayoutGridProps) => {

  return (
      <div className="layout-grid"
           style={{
             "--layout-grid-columns-desktop": props.desktop,
             "--layout-grid-columns-tablet": props.tablet,
             "--layout-grid-columns-mobile": props.mobile
           } as React.CSSProperties}>
        {props.children}
      </div>
  )
}

export default LayoutGrid;
