import {useEffect, useState} from "react";
import {apiRequestJson} from "../../services/api.service";
import {ProfessionInternshipResponse} from "../../dtos/profession.dto";
import {Method} from "../../enums/api.enum";
import {Profession, ProfessionModel, translateProfession} from "../../enums/profession.enum";
import {useNavigate} from "react-router-dom";
import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import Button from "../../components/base/button/button.component";
import {ButtonType} from "../../components/base/button/button.type";
import Spinner from "../../components/base/spinner/spinner.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";

const InternshipPage = () => {
    const navigate = useNavigate();
    const [professions, setProfession] = useState<Array<ProfessionInternshipResponse> | null>(null)

    useEffect(() => {
        apiRequestJson<Array<ProfessionInternshipResponse>>("v3/profession/" + ProfessionModel.PR, Method.GET, null, null)
            .then(responses => setProfession(responses))
            .catch(() => setProfession(null));
    })

    return (
        <>
            {
                professions !== null ? (
                    professions.map((profession) => (
                        <LayoutSection color={Color.BASE_INVERTED}>
                            <h2>{translateProfession(profession.profession as Profession)}</h2>
                            <h5>{profession.description}</h5>
                            <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
                                <Button
                                    color={Color.PRIMARY}
                                    type={ButtonType.OUTLINED}
                                    action={() => navigate("/ausbildungen/" + profession.profession + "/" + ProfessionModel.PR)}
                                >Mehr Erfahren</Button>
                            </LayoutSequence>
                        </LayoutSection>
                    ))
                ) : (
                    <Spinner/>
                )
            }
            <LayoutSection color={Color.BASE_INVERTED}>
                <h2>KV-Praktikum für Leistungssportler mit "Talent Card National"</h2>
                <h5>
                    Das Ausbildungsmodell ist so konzipiert, dass die Lernenden sämtliche Verpflichtungen aus Schule,
                    Betrieb und Sport unter einen Hut bringen können. Die kaufmännische Grundbildung dauert in der Regel
                    vier Jahre. Angeboten werden der Lehrgang zum Eidgenössischen Fähigkeitszeugnis (EFZ) als
                    Kauffrau/Kaufmann und die lehrbegleitende Berufsmatur (BM1) mit der Ausrichtung Wirtschaft &
                    Dienstleistung, Typ Wirtschaft. Die letzten 2 Jahre absolviert man ein Praktikum in einem
                    Praktikumsbetrieb (Pensum: 60-80%).
                </h5>
            </LayoutSection>
        </>
    )
}
export default InternshipPage;
