import {PromotionItemProps} from "./promotion-item.type";
import IdImage from "../../../base/id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";
import "./promotion-item.styles.scss";
import Button from "../../../base/button/button.component";
import {Color} from "../../../../enums/color.enum";
import {ButtonType} from "../../../base/button/button.type";
import {navigateExternal} from "../../../../services/navigation.service";

const PromotionItem = (props: PromotionItemProps) => {
  return (
      <div className="promotion-item">
        <div className="promotion-item__background">
          <IdImage id={props.promotion.imageId}
                   size={ImageSize.LANDSCAPE_LARGE}/>
        </div>
        <div className="promotion-item__body">
          <h1>{props.promotion.title}</h1>
          <div>
            <h4>{props.promotion.description}</h4>
            <Button color={Color.PRIMARY} type={ButtonType.OUTLINED}
                    action={() => navigateExternal(props.promotion.link, false)}>Mehr erfahren</Button>
          </div>
        </div>
      </div>
  )
}

export default PromotionItem;
