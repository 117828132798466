import {useEffect, useState} from "react";
import {CarouselProps} from "./carousel.type";
import "./carousel.styles.scss";
import CarouselItem from "./item/carousel-item.component";
import LayoutSequence from "../layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../layout/sequence/layout-sequence.type";
import Icon from "../icon/icon.component";
import {Size} from "../../../enums/size.enum";

const Carousel = (props: CarouselProps) => {
  const [index, setIndex] = useState(0);
  const length = props.items.length;

  const infiniteScroll = () => {
    setIndex(index === length - 1 ? 0 : index + 1);
  }

  useEffect(() => {
    const interval = setInterval(infiniteScroll, 6000)

    return () => clearInterval(interval);
  })

  return (
      <div className="carousel">
        <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
          {
            props.items.map((item, key) => {
              return (
                  <CarouselItem active={key === index ? "active" : ""}
                                key={key}>{item}</CarouselItem>)
            })
          }
        </LayoutSequence>
        <div className={"carousel__circles"}>
          <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
            {
              Array.from({length: length}, (_, i) =>
                  <div className={"carousel__circle" + (i === index ? " active" : "")}
                       onClick={() => setIndex(i)}
                       key={i}>
                    <Icon name={"circle"}
                          size={Size.XS}/>
                  </div>
              )
            }
          </LayoutSequence>
        </div>
      </div>
  )
}

export default Carousel;
