export const isEmailValid = (email: string) => {
  const regexExp: RegExp = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/gi;
  return regexExp.test(email)
}

export const empty = (value: any): boolean => {

  if (value === undefined) {
    return true;
  }

  if (value === null) {
    return true
  }

  if (typeof value === "string") {
    return value === "";
  }

  if (typeof value === "number") {
    return Number.isNaN(value);
  }

  return false;
}
