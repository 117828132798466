import {Profession, ProfessionModel, Subject} from "../enums/profession.enum";

export interface ProfessionDTO {
  profession: Profession,
  professionModel: ProfessionModel,
  description: string,
  imageId: string,
  openPositions: number,
  duration: number,
  daysSchool: number,
  daysCompany: number,
  schoolName: string,
  schoolLocation: string,
  schoolLink: string,
  interCompanyCourses: number,
  isBmPossible: boolean,
  associationLink: string,
  youtubeLink: string | null,
  dynamicComponentId: string,
  generalRequirements: Array<ProfessionGeneralRequirements>,
  schoolRequirements: Array<ProfessionSchoolRequirements>,
  additionalRequirements: Array<ProfessionAdditionalRequirements>
}

export interface ProfessionGeneralRequirements {
  title: string,
  description: string,
  icon: string
}

export interface ProfessionSchoolRequirements {
  subject: Subject,
  markSecondaryA: number,
  markSecondaryB: number,
  markSecondaryC: number
}

export interface ProfessionAdditionalRequirements {
  title: string,
  description: string
}

export interface ProfessionShortDTO {
  professionModel: ProfessionModel,
  description: string,
  imageId: string,
  openPositions: number,
  duration: number,
  daysSchool: number,
  daysCompany: number
}

export interface ProfessionOpenPositionResponse {
  profession: Profession,
  professionModel: ProfessionModel,
  openPositions: number
}

export interface ProfessionInternshipResponse {
  profession: Profession,
  description: string
}

export interface ProfessionInterest {
  icon: string,
  title: string
}

export const translateMark = (mark: number): string => {
  if (mark == null) {
    return "-"
  }

  return mark.toString();
}
