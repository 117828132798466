import {DynamicSpacerProps} from "./dynamic-spacer.type";
import {DynamicComponentSpacer} from "../../../../enums/dynamic-component.enum";

const DynamicSpacer = (props: DynamicSpacerProps) => {

  switch (props.dynamicComponent.type) {
    case DynamicComponentSpacer.BR:
      return <p><br/></p>
    case DynamicComponentSpacer.HR:
      return <hr/>
  }
}

export default DynamicSpacer
