import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import {Color} from "../../../enums/color.enum";
import TestimonialOverview from "../../../components/complex/testimonial/overview/testimonial-overview.component";

const TestimonialsPage = () => {

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Interviews</h1>
                <h5>
                    Wir haben unsere Lernenden interviewt, um zu erfahren, wie sie zu dieser Lehrstelle gekommen sind,
                    was sie über BRACK.CH als Lehrbetrieb denken und vieles mehr.
                </h5>
                <TestimonialOverview size={12}/>
            </LayoutSection>
        </>
    )
}

export default TestimonialsPage;
