import {Color} from "../../../enums/color.enum";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import Image from "../../../components/base/image/image.component";
import LayoutGrid from "../../../components/base/layout/grid/layout-grid.component";
import schnuppern1 from "../../../images/schnuppern/schnuppern1.jpg";
import schnuppern2 from "../../../images/schnuppern/schnuppern2.jpg";
import schnuppern3 from "../../../images/schnuppern/schnuppern3.jpg";
import schnuppern4 from "../../../images/schnuppern/schnuppern4.jpg";
import logistik1 from "../../../images/schnuppern/schnuppern_logistik_1.jpg";
import logistik2 from "../../../images/schnuppern/schnuppern_logistik_2.jpg";
import logistik3 from "../../../images/schnuppern/schnuppern_logistik_3.jpg";

const SchnuppernPage = () => {

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Schnuppern bei BRACK.CH in Mägenwil</h1>
                <h5>
                    Möchtest du mehr über deinen Wunschberuf und die dazugehörige Lehrstelle erfahren? Dann melde dich
                    jetzt für einen Berufsinfotag unter "<a href="/veranstaltungen">Veranstaltungen</a>" bei BRACK.CH
                    an. Unsere Lernenden und Berufsbildner*innen geben dir spannende Einblicke in ihren Beruf, ihren
                    Arbeitsalltag und beantworten all deine Fragen. Ein effektives Schnuppern über mehrere Tage in
                    einem bestimmten Beruf bieten wir erst im effektiven Bewerbungsprozess, nach einem erfolgreichen
                    Vorstellungsgespräch an. Wir freuen uns auf deine Berufsinfotag Bewerbung (Motivationsschreiben,
                    Lebenslauf, letztes Zeugnis)!
                </h5>
                <LayoutGrid desktop={2} tablet={2} mobile={2}>
                    <Image src={schnuppern1} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern2} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern3} alt="Schnuppern" isClickable={true}/>
                    <Image src={schnuppern4} alt="Schnuppern" isClickable={true}/>
                </LayoutGrid>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Schnuppern bei BRACK.CH Logistik in Willisau</h1>
                <h5>
                    Möchtest du mehr über den Logistiker Beruf in einem modernen Logistikzentrum und die dazugehörige
                    Lehrstelle erfahren? Dann melde dich für ein allfälliges Schnuppern in Willisau bei der Competec
                    Logistik über die E-Mailadresse <a
                    href="mailto:berufsbildung@brack.ch">berufsbildung@brack.ch</a> an. Wir freuen uns auf deine
                    persönliche Schnupper-Bewerbung (Motivationsschreiben, Lebenslauf, letztes Zeugnis)!
                </h5>
                <LayoutGrid desktop={3} tablet={3} mobile={1}>
                    <Image src={logistik1} alt="Schnuppern Logistik" isClickable={true}/>
                    <Image src={logistik2} alt="Schnuppern Logistik" isClickable={true}/>
                    <Image src={logistik3} alt="Schnuppern Logistik" isClickable={true}/>
                </LayoutGrid>
            </LayoutSection>
        </>
    )
}

export default SchnuppernPage;
