import {ReactNode} from "react";

export interface LayoutSequenceProps {
  children: ReactNode,
  direction: LayoutSequenceDirection
}

export enum LayoutSequenceDirection {
  ROW = "ROW",
  COLUMN = "COLUMN",
  ROW_CENTER = "ROW_CENTER",
  COLUMN_CENTER = "COLUMN_CENTER"
}
