import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Profession, professionDescription, translateProfession} from "../../enums/profession.enum";
import {apiRequestJson} from "../../services/api.service";
import {ProfessionShortDTO} from "../../dtos/profession.dto";
import {Method} from "../../enums/api.enum";
import ProfessionModel from "../../components/complex/profession/model/profession-model.component";
import LayoutGrid from "../../components/base/layout/grid/layout-grid.component";
import Spinner from "../../components/base/spinner/spinner.component";

const ModelsPage = () => {
  const {profession} = useParams()
  const [models, setModels] = useState<Array<ProfessionShortDTO> | null>(null)

  useEffect(() => {
    apiRequestJson<Array<ProfessionShortDTO>>("v3/profession/" + profession as Profession, Method.GET, null, null)
    .then(dtos => setModels(dtos))
    .catch(() => setModels(null));
  }, [profession])

  return (
      <>
        <LayoutSection color={Color.BASE_INVERTED}>
          <h1>{translateProfession(profession as Profession)}</h1>
          <h5>{professionDescription(profession as Profession)}</h5>
          <h5>Den Beruf {translateProfession(profession as Profession)} gibt es in folgenden Modellen:</h5>
        </LayoutSection>
        <LayoutSection color={Color.BASE_INVERTED}>
          {
            models !== null ? (
                <LayoutGrid desktop={2} tablet={1} mobile={1}>
                  {
                    models.map((model, key) => <ProfessionModel model={model} key={key}/>)
                  }
                </LayoutGrid>
            ) : (
                <Spinner/>
            )
          }
        </LayoutSection>
      </>
  )
}

export default ModelsPage;
