import "./profession-requirement.style.scss";
import {ProfessionRequirementProps} from "./profession-requirement.type";
import Icon from "../../../base/icon/icon.component";
import {Size} from "../../../../enums/size.enum";

const ProfessionRequirement = (props: ProfessionRequirementProps) => {

  return (
      <div className="profession-requirement">
        <Icon name={props.icon} size={Size.XL}/>
        <h5>{props.title}</h5>
        <div className="profession-requirement__description">
          <p>{props.description}</p>
        </div>
      </div>
  )
}

export default ProfessionRequirement;
