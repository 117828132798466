import {ReactNode} from "react";
import {Color} from "../../../enums/color.enum";

export interface ButtonProps {
  children: ReactNode,
  color: Color,
  type: ButtonType,
  action: () => void
}

export enum ButtonType {
  FILLED = "FILLED",
  OUTLINED = "OUTLINED",
  TEXT = "TEXT",
}
