import {useEffect, useState} from "react";
import {TestimonialShortDTO} from "../../../../dtos/testimonial.dto";
import {PaginationResponse, RequestParam} from "../../../../dtos/api.dto";
import {apiRequestJson} from "../../../../services/api.service";
import {Method} from "../../../../enums/api.enum";
import Spinner from "../../../base/spinner/spinner.component";
import TestimonialShortPreview from "./short/testimonial-short-preview.component";
import {TestimonialPreviewProps} from "./testimonial-preview.type";
import Carousel from "../../../base/carousel/carousel.component";


const TestimonialPreview = (props: TestimonialPreviewProps) => {
  const [testimonialShorts, setTestimonialShorts] = useState<PaginationResponse<TestimonialShortDTO> | null>(null)

  useEffect(() => {

    const params: Array<RequestParam> = [
      {key: "start", value: 0},
      {key: "size", value: 5}
    ]

    apiRequestJson<PaginationResponse<TestimonialShortDTO>>("v3/testimonial", Method.GET, params, null)
    .then(dtos => setTestimonialShorts(dtos))
    .catch(() => setTestimonialShorts(null))

  }, [props])

  return (
      <>
        {
          testimonialShorts !== null ? (
              <Carousel items={
                testimonialShorts.value.map((testimonialShort, key) =>
                    <TestimonialShortPreview testimonialShort={testimonialShort} key={key}/>
                )
              }/>
          ) : (
              <Spinner/>
          )
        }
      </>
  )
}

export default TestimonialPreview;
