import {IconProps} from "./icon.type";
import "./icon.styles.scss";

const Icon = (props: IconProps) => {
  return (
      <span className={"icon material-symbols-outlined " + (props.size !== undefined ? props.size : "")}>
        {props.name}
      </span>
  );
}

export default Icon;
