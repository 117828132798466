import "./about-us-marktplatz.style.scss";
import marktplatzOverview from "../../../../images/static/marktplatz-overview.svg";
import marktplatzProject from "../../../../images/static/marktplatz-project.svg";

const AboutUsMarktplatz = () => {
  return (
      <div className="marktplatz">
        <img src={marktplatzOverview}
             alt="Marktplatz"/>
        <img src={marktplatzProject}
             className="marktplatz-project"
             alt="Marktplatz Projekt"/>
      </div>
  )
}

export default AboutUsMarktplatz
