import "./profession.style.scss"
import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import LayoutArticle from "../../components/base/layout/article/layout-article.component";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {apiRequestJson} from "../../services/api.service";
import {ProfessionDTO, translateMark} from "../../dtos/profession.dto";
import {
    Profession,
    ProfessionModel,
    translateProfession,
    translateProfessionModel,
    translateSubject
} from "../../enums/profession.enum";
import {Method} from "../../enums/api.enum";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import IconText from "../../components/base/icon/text/icon-text.component";
import Dynamic from "../../components/base/dynamic/dynamic.component";
import Table from "../../components/base/table/table.component";
import LayoutGrid from "../../components/base/layout/grid/layout-grid.component";
import ProfessionRequirement from "../../components/complex/profession/requirement/profession-requirement.component";
import Spinner from "../../components/base/spinner/spinner.component";
import EventOverview from "../../components/complex/event/overview/event-overview.component";
import {empty} from "../../services/validation.service";

const ProfessionPage = () => {
    const {profession, model} = useParams();
    const [professionModel, setProfessionModel] = useState<ProfessionDTO | null>(null)

    useEffect(() => {
        apiRequestJson<ProfessionDTO>("v3/profession/" + profession as Profession + "/" + model as ProfessionModel, Method.GET, null, null)
            .then(dto => setProfessionModel(dto))
            .catch(() => setProfessionModel(null));
    }, [profession, model])

    return (
        <>
            {
                professionModel !== null ? (
                    <>
                        <LayoutArticle
                            imageId={professionModel.imageId}
                            title={translateProfession(professionModel.profession)}
                            subTilte={translateProfessionModel(professionModel.professionModel)}>
                            <LayoutSequence direction={LayoutSequenceDirection.COLUMN}>
                                <IconText
                                    icon="schedule"
                                    text={professionModel.duration + (professionModel.duration === 1 ? " Jahr" : " Jahre")}
                                />
                                {professionModel.daysSchool > 0 &&
                                    <IconText
                                        icon="school"
                                        text={professionModel.daysSchool + (professionModel.daysSchool === 1 ? " Tag Schule" : " Tage Schule")}
                                    />}
                                <IconText
                                    icon="work"
                                    text={professionModel.daysCompany + (professionModel.daysCompany === 1 ? " Tag Arbeit" : " Tage Arbeit")}
                                />
                                <IconText
                                    icon="check_circle"
                                    text={professionModel.openPositions + (professionModel.openPositions === 1 ? " Offene Stelle" : " Offene Stellen")}
                                />
                            </LayoutSequence>
                        </LayoutArticle>

                        <LayoutSection color={Color.BASE_INVERTED}>
                            <p>{professionModel.description}</p>
                            <p>
                                Weitere Informationen unter <a href={professionModel.associationLink} target="_blank"
                                                               rel="noreferrer"><strong>{professionModel.associationLink}</strong></a>
                            </p>
                            {
                                empty(professionModel.youtubeLink) || professionModel.youtubeLink === null ? null : (
                                    <iframe
                                        src={professionModel.youtubeLink}
                                        style={{width: "100%", aspectRatio: "16/9"}}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen>
                                    </iframe>
                                )
                            }
                        </LayoutSection>

                        <LayoutSection color={Color.BASE}>
                            <h2>Schule</h2>
                            <p>
                                {professionModel.schoolName} ({professionModel.schoolLocation})
                                <br/>
                                {professionModel.daysSchool > 0 &&
                                    <>
                                        {professionModel.daysSchool} Schultage
                                        <br/>
                                    </>
                                }
                                {professionModel.interCompanyCourses} Überbetriebliche Kurstage
                                <br/>
                                Berufsmatura {professionModel.isBmPossible ? "möglich" : "nicht möglich"}
                            </p>
                            <a href={professionModel.schoolLink} target="_blank"
                               rel="noreferrer">{professionModel.schoolLink}</a>
                            {professionModel.schoolRequirements.length > 0 &&
                                <Table
                                    header={["Fach", "Realschule (SEK C)", "Sekundarschule (SEK B)", "Bezirksschule (SEK A)"]}
                                    items={professionModel.schoolRequirements.map(
                                        schoolRequirement => [
                                            translateSubject(schoolRequirement.subject),
                                            translateMark(schoolRequirement.markSecondaryC),
                                            translateMark(schoolRequirement.markSecondaryB),
                                            translateMark(schoolRequirement.markSecondaryA)
                                        ])}/>
                            }
                        </LayoutSection>

                        <LayoutSection color={Color.BASE}>
                            <h2>Allgemeine Anforderungen</h2>
                            <LayoutGrid desktop={4} tablet={2} mobile={1}>
                                {
                                    professionModel.generalRequirements.map((generalRequirement, key) =>
                                        <ProfessionRequirement
                                            icon={generalRequirement.icon}
                                            title={generalRequirement.title}
                                            description={generalRequirement.description}
                                            key={key}/>)
                                }
                            </LayoutGrid>
                        </LayoutSection>

                        <LayoutSection color={Color.BASE}>
                            <h2>Zusätzliche Anforderungen</h2>
                            <ul className="requirements">
                                {
                                    professionModel.additionalRequirements.map((additionalRequirement, key) => (
                                        <li key={key}>
                                            <h6>{additionalRequirement.title}</h6>
                                            <p>{additionalRequirement.description}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </LayoutSection>

                        <LayoutSection color={Color.BASE_INVERTED}>
                            <Dynamic id={professionModel.dynamicComponentId}/>
                        </LayoutSection>

                        <LayoutSection color={Color.BASE_INVERTED}>
                            <h2>Veranstaltungen für {translateProfession(professionModel.profession)}</h2>
                            <EventOverview profession={professionModel.profession} start={0} size={6} eventType={null}/>
                        </LayoutSection>
                    </>
                ) : (
                    <Spinner/>
                )
            }
        </>
    )
}

export default ProfessionPage;
