import {Method} from "../enums/api.enum";
import {RequestParam} from "../dtos/api.dto";
import {toastyError, toastyInfo} from "./toasty.service";

export const apiRequest = async (
    path: string,
    method: Method,
    params: Array<RequestParam> | null,
    body: any | null): Promise<Response> => {

  let url = constructUrl(path, params);

  let response = await fetch(url, {
    method: method,
    body: body !== null ? JSON.stringify(body) : null,
    mode: "cors",
    cache: "default",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (response.status === 500) {
    toastyError("Es ist ein Problem aufgetreten, versuchen Sie es später noch einmal (500)")
    throw new Error();
  }

  if (response.status === 418) {
    toastyInfo("I'm a teapot")
    throw new Error();
  }

  if (response.status === 404) {
    throw new Error();
  }

  if (response.status === 401) {
    toastyInfo("Keine Berechtigung")
    throw new Error();
  }

  if (response.status === 400) {
    toastyError("Es ist ein Problem aufgetreten, versuchen Sie es später noch einmal (400)")
    throw new Error();
  }

  if (response.status !== 200) {
    toastyError("Es ist ein Problem aufgetreten, versuchen Sie es später noch einmal (!200)")
    throw new Error();
  }

  return response;
}

export const apiRequestJson = async <T>(
    path: string,
    method: Method,
    params: Array<RequestParam> | null,
    body: any | null): Promise<T> => {

  let response = await apiRequest(path, method, params, body);

  return await response.json();
}

const constructUrl = (
    path: string,
    params: Array<RequestParam> | null) => {

  let url = process.env.REACT_APP_STIFTIPEDIA_API_URL + path;

  if (params !== null && params.length > 0) {
    url += "?";

    for (let i = 0; i < params.length; i++) {
      url += params[i].key + "=" + params[i].value

      if (i !== params.length - 1) {
        url += "&"
      }
    }
  }

  return url;
}
