import "./profession-card.style.scss";
import {ProfessionCardProps} from "./profession-card.type";
import {professionInterests, translateProfession} from "../../../../enums/profession.enum";
import Image from "../../../base/image/image.component";
import IconText from "../../../base/icon/text/icon-text.component";
import {Link} from "react-router-dom";

const ProfessionCard = (props: ProfessionCardProps) => {
  return (
      <Link to={props.profession}>
        <div className={"profession-card " + props.imagePosition}>
          <div className="profession-card__body">
            <h2>{translateProfession(props.profession)}</h2>
            <p>{props.description}</p>
            <div className="profession-card__body-interests">
              {
                professionInterests(props.profession).map((interest, key) =>
                    <IconText icon={interest.icon} text={interest.title} key={key}/>
                )
              }
            </div>
          </div>
          <Image src={props.image} alt={translateProfession(props.profession)}/>
        </div>
      </Link>
  )
}

export default ProfessionCard;
