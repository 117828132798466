import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/style.scss';
import {BrowserRouter} from "react-router-dom";
import App from "./app";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </React.StrictMode>
);
