import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {apiRequestJson} from "../../services/api.service";
import {Method} from "../../enums/api.enum";
import {EventDTO} from "../../dtos/event.dto";
import LayoutArticle from "../../components/base/layout/article/layout-article.component";
import {convertToDate, dateString} from "../../services/date.service";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Chip from "../../components/base/chip/chip.component";
import {translateProfession} from "../../enums/profession.enum";
import Button from "../../components/base/button/button.component";
import {ButtonType} from "../../components/base/button/button.type";
import Dynamic from "../../components/base/dynamic/dynamic.component";
import Spinner from "../../components/base/spinner/spinner.component";
import {navigateExternal} from "../../services/navigation.service";
import {empty} from "../../services/validation.service";

const EventPage = () => {
  const {id} = useParams()
  const [event, setEvent] = useState<EventDTO | null>(null)
  const [date, setDate] = useState<string>("")

  useEffect(() => {
    apiRequestJson<EventDTO>("v3/event/" + id, Method.GET, null, null)
    .then(dto => setEvent(dto))
    .catch(() => setEvent(null));
  }, [id])

  useEffect(() => {
    if (event !== null) {
      const dateFrom = convertToDate(event.dateFrom);
      const dateTo = convertToDate(event.dateTo);

      if (dateFrom.getTime() === dateTo.getTime()) {
        setDate(dateString(event.dateFrom));
      } else {
        setDate(dateString(event.dateFrom) + " - " + dateString(event.dateTo));
      }
    }
  }, [event])


  return (
      <>
        {
          event !== null ? (
              <>
                <LayoutArticle imageId={event.imageId}
                               title={event.title}
                               subTilte={date + " | " + event.location}>
                  <LayoutSequence direction={LayoutSequenceDirection.COLUMN}>
                    <LayoutSequence direction={LayoutSequenceDirection.ROW}>
                      {
                        event.eventProfession.map((eventProfession, key) =>
                            <Chip key={key}>
                              {translateProfession(eventProfession.profession)}
                            </Chip>)
                      }
                    </LayoutSequence>
                    <h3>{event.description}</h3>
                    {
                      !empty(event.link) ? (
                          <Button color={Color.PRIMARY}
                                  type={ButtonType.FILLED}
                                  action={() => {
                                    // @ts-ignore
                                    navigateExternal(event?.link, true)
                                  }}>Mehr erfahren</Button>
                      ) : null
                    }
                  </LayoutSequence>
                </LayoutArticle>
                <LayoutSection color={Color.BASE_INVERTED}>
                  <Dynamic id={event.dynamicComponentId}/>
                </LayoutSection>
              </>
          ) : (
              <Spinner/>
          )
        }
      </>
  )
}

export default EventPage;
