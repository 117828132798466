import {EventOverviewProps} from "./event-overview.type";
import {useEffect, useState} from "react";
import {EventShortDTO} from "../../../../dtos/event.dto";
import {RequestParam} from "../../../../dtos/api.dto";
import {apiRequestJson} from "../../../../services/api.service";
import {Method} from "../../../../enums/api.enum";
import EventShort from "../short/event-short.component";
import LayoutGrid from "../../../base/layout/grid/layout-grid.component";

const EventOverview = (props: EventOverviewProps) => {
    const [eventShorts, setEventShorts] = useState<Array<EventShortDTO> | null>(null);

    useEffect(() => {
        const params: Array<RequestParam> = [
            {key: "start", value: props.start},
            {key: "size", value: props.size}
        ]

        if (props.profession !== null) {
            params.push({key: "profession", value: props.profession})
        }

        if (props.eventType !== null) {
            params.push({key: "eventType", value: props.eventType})
        }

        apiRequestJson<Array<EventShortDTO>>("v3/event", Method.GET, params, null)
            .then(dtos => setEventShorts(dtos))
            .catch(() => setEventShorts(null));
    }, [props])

    return (
        <>
            {
                eventShorts !== null ? (
                    <LayoutGrid desktop={2} tablet={2} mobile={1}>
                        {
                            eventShorts.map((eventShort, key) =>
                                <EventShort eventShort={eventShort} key={key}/>
                            )
                        }
                    </LayoutGrid>
                ) : (
                    <h4>Leider haben wir keine Veranstaltungen gefunden</h4>
                )
            }
        </>
    )
}

export default EventOverview;
