import {DynamicButtonProps} from "./dynamic-button.type";
import Button from "../../button/button.component";
import {Color} from "../../../../enums/color.enum";
import {ButtonType} from "../../button/button.type";
import LayoutSequence from "../../layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../layout/sequence/layout-sequence.type";
import {navigateExternal} from "../../../../services/navigation.service";

const DynamicButton = (props: DynamicButtonProps) => {

  return (
      <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
        <Button color={Color.PRIMARY} type={ButtonType.FILLED}
                action={() => navigateExternal(props.dynamicComponent.href, true)}>
          {props.dynamicComponent.value}
        </Button>
      </LayoutSequence>
  )
}

export default DynamicButton
