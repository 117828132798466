import {LayoutSummaryProps} from "./layout-summary.type";
import "./layout-summary.style.scss";
import Button from "../../button/button.component";
import {ButtonType} from "../../button/button.type";
import {useState} from "react";
import useWindowDimensions from "../../../../hooks/window-dimension.hook";
import IconButton from "../../icon/button/icon-button.component";
import {Size} from "../../../../enums/size.enum";
import {Color} from "../../../../enums/color.enum";
import Icon from "../../icon/icon.component";

const LayoutSummary = (props: LayoutSummaryProps) => {
    const [selected, setSelected] = useState<number>(0);
    const {width} = useWindowDimensions();

    const goBack = () => setSelected((selected === 0) ? props.elements.length - 1 : selected - 1);

    const goForward = () => setSelected((selected === props.elements.length - 1) ? 0 : selected + 1);

    const renderContent = () => {
        return props.elements.length !== 0 ? props.elements[selected].children : <p>Keine Daten gefunden</p>;
    };

    const renderNavigation = () => {
        return (
            <div className="layout-summary__navigation">
                {props.elements.map((element, key) => (
                    <div className="layout-summary__navigation-element" key={key}>
                        <div
                            className={`layout-summary__navigation-element-indicator ${selected === key ? "current " : ""}${props.color}`}
                        />
                        <Button
                            color={props.color}
                            type={ButtonType.TEXT}
                            action={() => setSelected(key)}
                        >
                            {element.title}
                        </Button>
                    </div>
                ))}
            </div>
        );
    };

    const renderCircles = () => {
        return (
            <div className="circles">
                {Array.from({ length: props.elements.length }, (_, i) => (
                    <div className={"circle" + (selected === i ? " current" : "")}
                         onClick={() => setSelected(i)}
                         key={i}
                    >
                        <Icon name={"circle"} size={Size.XS}/>
                    </div>
                ))}
            </div>
        );
    }


    return (
        <div className="layout-summary">
            {width > 800 ? (
                <>
                    {renderNavigation()}
                    <div className="layout-summary__content">{renderContent()}</div>
                </>
            ) : (
                <>
                    <div className="layout-summary__navigation">
                        <IconButton name="arrow_back_ios" size={Size.S} color={Color.BASE} action={goBack}/>
                    </div>
                    <div className="layout-summary__content">
                        {renderContent()}
                        {renderCircles()}
                    </div>
                    <div className="layout-summary__navigation">
                        <IconButton name="arrow_forward_ios" size={Size.S} color={Color.BASE} action={goForward}/>
                    </div>
                </>
            )}
        </div>
    );
};

export default LayoutSummary;
