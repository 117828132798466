import "./blog-overview.style.scss"
import BlogShort from "../short/blog-short.component";
import {useEffect, useState} from "react";
import {BlogShortDTO} from "../../../../dtos/blog.dto";
import {PaginationResponse, RequestParam} from "../../../../dtos/api.dto";
import {apiRequestJson} from "../../../../services/api.service";
import {Method} from "../../../../enums/api.enum";
import LayoutGrid from "../../../base/layout/grid/layout-grid.component";
import {BlogOverviewProps} from "./blog-overview.type";
import Pagination from "../../../base/pagination/pagination.component";

const BlogOverview = (props: BlogOverviewProps) => {
    const [blogShorts, setBlogShorts] = useState<PaginationResponse<BlogShortDTO> | null>(null);
    const [start, setStart] = useState(0);

    useEffect(() => {

        const params: Array<RequestParam> = [
            {key: "start", value: start},
            {key: "size", value: props.size}
        ]

        if (props.tag !== null) {
            params.push({key: "blogTag", value: props.tag})
        }

        apiRequestJson<PaginationResponse<BlogShortDTO>>("v3/blog", Method.GET, params, null)
            .then(dtos => {
                setBlogShorts(dtos)
            })
            .catch(() => setBlogShorts(null))

    }, [props, start])

    return (
        <>
            {
                blogShorts !== null ? (
                    <>
                        <LayoutGrid desktop={3} tablet={2} mobile={1}>
                            {
                                blogShorts.value.map((blogShort, key) =>
                                    <BlogShort blogShort={blogShort} key={key}/>
                                )
                            }
                        </LayoutGrid>
                        <Pagination
                            setStart={setStart}
                            size={props.size}
                            total={blogShorts.total}/>
                    </>
                ) : (
                    <h4>Leider haben wir keine News gefunden</h4>
                )
            }
        </>
    )
}

export default BlogOverview;
