import {ToastyType} from "../components/complex/toasty/toasty.type";

let subscribers: Array<(message: string, type: ToastyType) => void> = [];

export const toastyError = (message: string) => {
  subscribers.forEach(subscriber => {
    subscriber(message, ToastyType.ERROR)
  })
}

export const toastyInfo = (message: string) => {
  subscribers.forEach(subscriber => {
    subscriber(message, ToastyType.INFO)
  })
}

export const toastySuccess = (message: string) => {
  subscribers.forEach(subscriber => {
    subscriber(message, ToastyType.SUCCESS)
  })
}

export const toastySubscribe = (callback: (message: string, type: ToastyType) => void) => {
  subscribers.push(callback);
}


export const toastyUnsubscribe = (callback: (message: string, type: ToastyType) => void) => {
  subscribers.filter(subscribers => subscribers !== callback);
}
