import "./profession-summary.style.scss";
import {ProfessionSummaryProps} from "./profession-summary.type";
import Image from "../../../base/image/image.component";
import {professionInterests, translateProfession} from "../../../../enums/profession.enum";
import Button from "../../../base/button/button.component";
import {Color} from "../../../../enums/color.enum";
import {ButtonType} from "../../../base/button/button.type";
import {useNavigate} from "react-router-dom";
import IconText from "../../../base/icon/text/icon-text.component";
import LayoutGrid from "../../../base/layout/grid/layout-grid.component";

const ProfessionSummary = (props: ProfessionSummaryProps) => {
    const navigate = useNavigate();
    const profession = translateProfession(props.profession);

    return (
        <div className="profession-summary">
            <Image src={props.image} alt={profession} isClickable={true}/>
            <div className="profession-summary__head">
                <h3>{profession}</h3>
                <Button
                    color={Color.PRIMARY}
                    type={ButtonType.OUTLINED}
                    action={() => navigate("ausbildungen/" + props.profession)}>
                    Mehr erfahren
                </Button>
            </div>
            <div className="profession-summary__body">
                <LayoutGrid desktop={2} tablet={2} mobile={1}>
                    {
                        professionInterests(props.profession).map((interest, key) =>
                            <IconText icon={interest.icon} text={interest.title} key={key}/>
                        )
                    }
                </LayoutGrid>
            </div>
        </div>
    )
}

export default ProfessionSummary;
