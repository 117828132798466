import "./footer-tile.style.scss";
import {FooterTileProps} from "./footer-tile.type";

const FooterTile = (props: FooterTileProps) => {
  return (
      <div className="footer-tile"
           onMouseEnter={props.onMouseEnter}
           onMouseLeave={props.onMouseLeave}>
        {props.children}
      </div>
  )
}

export default FooterTile;
