import "./toasty-item.style.scss";
import {ToastyItemProps} from "./toasty-item.type";
import Icon from "../../../base/icon/icon.component";
import {ToastyType} from "../toasty.type";
import IconButton from "../../../base/icon/button/icon-button.component";
import {Size} from "../../../../enums/size.enum";
import {Color} from "../../../../enums/color.enum";

const ToastyItem = (props: ToastyItemProps) => {

  const icon = () => {
    switch (props.type) {
      case ToastyType.ERROR:
        return "dangerous";
      case ToastyType.INFO:
        return "settings";
      case ToastyType.SUCCESS:
        return "check_circle";
      default:
        return "question_mark";
    }
  }

  return (
      <div className={"toasty-item " + props.type}>
        <Icon name={icon()} size={Size.M}/>
        <p>{props.message}</p>
        <IconButton name="close"
                    size={Size.S}
                    color={Color.BASE_INVERTED}
                    action={() => props.remove(props.id)}/>
      </div>
  )
}

export default ToastyItem;
