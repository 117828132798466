import {ButtonProps} from "./button.type";
import "./button.styles.scss";

const Button = (props: ButtonProps) => {
  return (
      <button onClick={props.action}
              className={"button " + props.color + " " + props.type}>
        {props.children}
      </button>
  );
}

export default Button;
