import "./layout-sequence.style.scss";
import {LayoutSequenceProps} from "./layout-sequence.type";

const LayoutSequence = (props: LayoutSequenceProps) => {
  return (
      <div className={"layout-sequence " + props.direction}>
        {props.children}
      </div>
  )
}

export default LayoutSequence
