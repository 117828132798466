import {DropdownItemProps} from "./dropdown-item.type";
import "./dropdown-item.styles.scss";

const DropdownItem = (props: DropdownItemProps) => {
  const handleOnClick = () => {
    props.updateValue(props.option)
    props.setVisible(false)
  }

  return (
      <span className="dropdown-item"
            onClick={() => handleOnClick()}>
        {props.option.display}
      </span>
  )
}

export default DropdownItem;
