import {useEffect, useState} from "react";
import {PromotionDTO} from "../../../dtos/promotion.dto";
import {apiRequestJson} from "../../../services/api.service";
import {Method} from "../../../enums/api.enum";
import Spinner from "../../base/spinner/spinner.component";
import PromotionItem from "./item/promotion-item.component";
import Carousel from "../../base/carousel/carousel.component";
import "./promotion.styles.scss";
import useWindowDimensions from "../../../hooks/window-dimension.hook";

const PromotionPreview = () => {
  const [promotions, setPromotions] = useState<Array<PromotionDTO> | null>(null)
  const {width} = useWindowDimensions();

  useEffect(() => {
    apiRequestJson<Array<PromotionDTO>>("v3/promotion", Method.GET, null, null)
    .then(dtos => setPromotions(dtos))
    .catch(() => setPromotions(null));
  }, [])

  return (
      <div className={width < 1088 ? "" : "promotion"}>
        {
          promotions !== null ? (
              <Carousel items={
                promotions.map((promotion, key) =>
                    <PromotionItem promotion={promotion} key={key}/>
                )
              }/>
          ) : (
              <Spinner/>
          )
        }
      </div>
  )
}

export default PromotionPreview;
