import {empty} from "./validation.service";

export const navigateExternal = (url: string, newTab?: boolean) => {
  if (newTab === undefined) {
    newTab = false
  }

  if (empty(url)) {
    return
  }

  window.open(url, newTab ? "_blank" : "_self")
}
