export enum BlogTag {
  TRIAL_APPRENTICESHIP = "TRIAL_APPRENTICESHIP",
  FIRST_STEPS = "FIRST_STEPS",
  PROJECTS = "PROJECTS",
  TRIPS = "TRIPS",
  AWARDS = "AWARDS",
  EXHIBITION = "EXHIBITION",
  GRADUATION = "GRADUATION"
}

export const translateBlogTag = (blogtag: BlogTag): string => {
  switch (blogtag) {
    case BlogTag.TRIAL_APPRENTICESHIP:
      return "Schnuppertage";
    case BlogTag.FIRST_STEPS:
      return "First-Steps";
    case BlogTag.PROJECTS:
      return "Projekte";
    case BlogTag.TRIPS:
      return "Ausflüge";
    case BlogTag.AWARDS:
      return "Auszeichnungen";
    case BlogTag.EXHIBITION:
      return "Ausstellungen";
    case BlogTag.GRADUATION:
      return "Lehrabschluss";
  }
}
