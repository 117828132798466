import "./about-us-head.style.scss";
import {AboutUsHeadProps} from "./about-us-head.type";
import Image from "../../../base/image/image.component";

const AboutUsHead = (props: AboutUsHeadProps) => {

    return (
        <div className="about-us-head">
            {
                props.img &&
                <Image src={props.img} alt={props.name} isClickable={true}/>
            }
            <h4>{props.name}</h4>
            <p>{props.title}</p>
        </div>
    )
}

export default AboutUsHead;
