import "./nav-drawer.style.scss";
import {NavDrawerProps} from "./nav-drawer.type";
import {NavLink} from "react-router-dom";

const NavDrawer = (props: NavDrawerProps) => {

    const close = () => {
        props.updateSelectedPage(null);
        props.updateOpen();
    }

    return (
        <div className="nav-drawer">
            {props.selectedPage?.subPages ? (
                <>
                    <NavLink
                        to={props.selectedPage.path}
                        className="nav-drawer__link"
                        onClick={close}
                    >
                        <h2>{props.selectedPage.name}</h2>
                    </NavLink>
                    <ul className="nav-sublinks">
                        {props.selectedPage.subPages.map((subPage) => (
                            <li key={subPage.path}>
                                <NavLink
                                    to={subPage.path}
                                    className="nav-drawer_sublink"
                                    onClick={close}
                                >
                                    <h3>{subPage.name}</h3>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <ul className="nav-links">
                    {props.pages.map((page) => (
                        <li key={page.path}>
                            {page.subPages ? (
                                <div
                                    className="nav-drawer__link"
                                    onClick={() => props.updateSelectedPage(page)}
                                >
                                    <h2>{page.name}</h2>
                                </div>
                            ) : (
                                <NavLink
                                    to={page.path}
                                    className="nav-drawer__link"
                                    onClick={props.updateOpen}
                                >
                                    <h2>{page.name}</h2>
                                </NavLink>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NavDrawer;
