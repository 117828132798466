import "./nav-item.style.scss";
import {NavItemProps} from "./nav-item.type";
import {useState} from "react";
import {NavLink} from "react-router-dom";

const NavItem = (props: NavItemProps) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);

    return (
        <div
            className="nav-item"
            onMouseEnter={() => setOpenDropdown(true)}
            onMouseLeave={() => setOpenDropdown(false)}>
            <NavLink to={props.page.path} className="nav-item__link">{props.page.name}</NavLink>
            {
                props.page.subPages && openDropdown &&
                <ul className="nav-item__dropdown">
                    {
                        props.page.subPages.map((subPage) => (
                            <li key={subPage.path}>
                                <NavLink to={subPage.path} className="nav-item__dropdown-link">{subPage.name}</NavLink>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

export default NavItem;
