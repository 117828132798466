import './dropdown.styles.scss';
import {DropdownProps} from "./dropdown.type";
import DropdownButton from "./button/dropdown-button.component";
import DropdownItem from "./item/dropdown-item.component";
import {useState} from "react";

const Dropdown = (props: DropdownProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
      <div className="dropdown">
        <DropdownButton value={props.value.display}
                        visible={visible}
                        setVisible={setVisible}/>
        <div className={"dropdown__container " + (visible ? "visible" : "")}>
          <div className="dropdown__list">
            {
              props.options.map((option, key) => (
                      <DropdownItem option={option}
                                    setVisible={setVisible}
                                    updateValue={props.updateValue}
                                    key={key}/>
                  )
              )
            }
          </div>
        </div>
      </div>

  )
}

export default Dropdown;
