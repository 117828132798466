import "./about-us-way.style.scss";
import LayoutSequence from "../../../base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../base/layout/sequence/layout-sequence.type";
import Icon from "../../../base/icon/icon.component";
import {Size} from "../../../../enums/size.enum";

const AboutUsWay = () => {

  return (
      <div className="about-us-way">
        <div className="about-us-way__container">
          <h4>Bewerben</h4>
          <p>Nur mit einer sauberen Bewerbung hast du die Chance auf deine Traumlehrstelle in deiner Traumfirma.</p>
        </div>
        <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
          <Icon name="arrow_downward" size={Size.XL}/>
        </LayoutSequence>
        <div className="about-us-way__container">
          <h4>Online Interview</h4>
          <p>Etwa 20% der Bewerber werden zu einem 20 Minuten Online-Interview über Teams eingeladen.</p>
        </div>
        <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
          <Icon name="arrow_downward" size={Size.XL}/>
        </LayoutSequence>
        <div className="about-us-way__container">
          <h4>Bewerbungs- oder Vorstellungsgespräch</h4>
          <p>Überzeugende Bewerber am Online-Interview werden nachträglich zu einem max. 2 stündigen, physischen
            Bewerbungsgespräch eingeladen. </p>
        </div>
        <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
          <Icon name="arrow_downward" size={Size.XL}/>
        </LayoutSequence>
        <div className="about-us-way__container">
          <h4>Zu- oder Absage</h4>
          <p>Dein tolles Bewerbungsdossier, das überzeugende Online-Interview und der persönlich Eindruck am
            Bewerbungsgespräch ist schlussendlich entscheidend für eine Zu- oder Absage der beworbenen Lehrstelle.</p>
        </div>
      </div>
  )
}

export default AboutUsWay;
