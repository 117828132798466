export enum DynamicComponent {
  GRID = "GRID",
  TEXT = "TEXT",
  SPACER = "SPACER",
  IMAGE = "IMAGE",
  BUTTON = "BUTTON"
}

export enum DynamicComponentSpacer {
  BR = "BR",
  HR = "HR"
}

export enum DynamicComponentText {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
  H6 = "H6",
  P = "P",
  SMALL = "SMALL",
  BLOCKQUOTE = "BLOCKQUOTE",
  PRE = "PRE"
}
