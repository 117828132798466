import "./vacancies.style.scss"
import {Color} from "../../../enums/color.enum";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import LayoutSummary from "../../../components/base/layout/summary/layout-summary.component";
import {useEffect, useState} from "react";
import {LayoutSummaryElement} from "../../../components/base/layout/summary/layout-summary.type";
import {apiRequestJson} from "../../../services/api.service";
import {ProfessionOpenPositionResponse} from "../../../dtos/profession.dto";
import {Method} from "../../../enums/api.enum";
import {ProfessionModel, translateProfessionSimple} from "../../../enums/profession.enum";

const VacanciesPage = () => {
    const [elements, setElements] = useState<Array<LayoutSummaryElement>>([]);

    useEffect(() => {
        apiRequestJson<Array<ProfessionOpenPositionResponse>>("v3/profession/openPositions", Method.GET, null, null)
            .then(dtos => {
                dtos.forEach(dto => {
                    const element: LayoutSummaryElement = {
                        title: translateProfessionSimple(dto.profession) + (dto.professionModel === ProfessionModel.PR ? " SOG" : " EFZ"),
                        children: (
                            <>
                                <h4>Aktuell sind</h4>
                                <h1>{dto.openPositions}</h1>
                                <h4>Stellen offen für das Jahr 2025</h4>
                                <h4>als {translateProfessionSimple(dto.profession) + (dto.professionModel === ProfessionModel.PR ? " SOG" : " EFZ")}</h4>
                            </>
                        )
                    }

                    setElements(prevState => [...prevState, element])
                })
            })
            .catch(() => setElements([]))
    }, []);

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Lehr- und Praktikumstellen</h1>
                <div className="vacancies-summery">
                    <LayoutSummary elements={elements} color={Color.BASE}/>
                </div>
            </LayoutSection>
        </>
    )
}

export default VacanciesPage;
