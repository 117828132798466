import "./event-short.style.scss";
import {EventShortProps} from "./event-short.type";
import {Link} from "react-router-dom";
import IconText from "../../../base/icon/text/icon-text.component";
import {ReactNode, useEffect, useState} from "react";
import {convertToDate} from "../../../../services/date.service";

const EventShort = (props: EventShortProps) => {
  const [date, setDate] = useState<ReactNode>()

  useEffect(() => {
    const dateFrom = convertToDate(props.eventShort.dateFrom);
    const dateTo = convertToDate(props.eventShort.dateTo);

    if (dateFrom.getTime() === dateTo.getTime()) {
      setDate((
          <>
            <h2>{dateFrom.getDate()}</h2>
            <h4>{dateFrom.toLocaleString('default', {month: 'long'})}</h4>
          </>
      ))
    } else {
      setDate((
          <>
            <div className="event-short__head-date">
              <h2>{dateFrom.getDate()}</h2>
              <h6>{dateFrom.toLocaleString('default', {month: 'long'})}</h6>
            </div>
            <p>bis</p>
            <div className="event-short__head-date">
              <h2>{dateTo.getDate()}</h2>
              <h6>{dateTo.toLocaleString('default', {month: 'long'})}</h6>
            </div>
          </>
      ))
    }


  }, [props])

  return (
      <Link to={"/veranstaltungen/" + props.eventShort.id}>
        <div className={"event-short " + props.eventShort.eventType}>
          <div className="event-short__head">
            {date}
          </div>
          <div className="event-short__body">
            <h4>{props.eventShort.title}</h4>
            <IconText icon="location_on" text={props.eventShort.location}/>
          </div>
        </div>
      </Link>
  )
}

export default EventShort;
