import "./spinner.styles.scss";

const Spinner = () => {

  return (
      <div className="spinner__container">
        <div className="spinner"></div>
      </div>
  );
}

export default Spinner;
