import "./icon-text.style.scss";
import {IconTextProps} from "./icon-text.type";
import Icon from "../icon.component";
import {Size} from "../../../../enums/size.enum";

const IconText = (props: IconTextProps) => {
  return (
      <div className="icon-text">
        <Icon name={props.icon} size={Size.M}/>
        <h6>{props.text}</h6>
      </div>
  )
}

export default IconText;
