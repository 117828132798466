import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import Button from "../../components/base/button/button.component";
import {ButtonType} from "../../components/base/button/button.type";
import {useNavigate} from "react-router-dom";
import LayoutSequence from "../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../components/base/layout/sequence/layout-sequence.type";
import Image from "../../components/base/image/image.component";
import image404 from "../../images/static/404.jpg";
import "./not-found.style.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
      <>
        <LayoutSection color={Color.BASE_INVERTED}>
          <h1>Seite nicht gefunden</h1>
          <h5>Leider wurde diese Seite nicht gefunden.
            <br/>Möglicherweise hast du den Link nicht richtig eingegeben. Versuche es nochmals oder gehe zurück.
          </h5>
          <LayoutSequence direction={LayoutSequenceDirection.ROW_CENTER}>
            <Button color={Color.PRIMARY} type={ButtonType.OUTLINED} action={() => navigate("/")}>Zurück zur
              Homepage</Button>
          </LayoutSequence>
          <div className="not-found__image">
            <Image src={image404} alt="Autostore 404"/>
          </div>
        </LayoutSection>
      </>
  )
}

export default NotFoundPage;
