import {useEffect, useState} from "react";
import {Profession, translateProfession} from "../../../../enums/profession.enum";
import {PaginationResponse, RequestParam} from "../../../../dtos/api.dto";
import {DropdownOption} from "../../../base/dropdown/dropdown.type";
import {apiRequestJson} from "../../../../services/api.service";
import {TestimonialShortDTO} from "../../../../dtos/testimonial.dto";
import {Method} from "../../../../enums/api.enum";
import Dropdown from "../../../base/dropdown/dropdown.component";
import Spinner from "../../../base/spinner/spinner.component";
import LayoutGrid from "../../../base/layout/grid/layout-grid.component";
import TestimonialShort from "../short/testimonial-short.component";
import {TestimonialOverviewProps} from "./testimonial-overview.type";
import Pagination from "../../../base/pagination/pagination.component";

const TestimonialOverview = (props: TestimonialOverviewProps) => {

    const [testimonialShorts, setTestimonialShorts] = useState<PaginationResponse<TestimonialShortDTO> | null>(null)
    const [selectedProfession, setSelectedProfession] = useState<DropdownOption>({key: null, display: "Beruf"})
    const [start, setStart] = useState(0)

    const professionOptions: Array<DropdownOption> = [
        {
            key: null,
            display: "Alle"
        },
        {
            key: Profession.ME,
            display: translateProfession(Profession.ME)
        },
        {
            key: Profession.IA,
            display: translateProfession(Profession.IA)
        },
        {
            key: Profession.IP,
            display: translateProfession(Profession.IP)
        },
        {
            key: Profession.IF,
            display: translateProfession(Profession.IF)
        },
        {
            key: Profession.EDB,
            display: translateProfession(Profession.EDB)
        },
        {
            key: Profession.LO,
            display: translateProfession(Profession.LO)
        },
        {
            key: Profession.PM,
            display: translateProfession(Profession.PM)
        },
        {
            key: Profession.KF,
            display: translateProfession(Profession.KF)
        },
        {
            key: Profession.FK,
            display: translateProfession(Profession.FK)
        }
    ]

    useEffect(() => {

        const params: Array<RequestParam> = [
            {key: "start", value: start},
            {key: "size", value: props.size}
        ]

        if (selectedProfession.key !== null) {
            params.push({key: "profession", value: selectedProfession.key})
        }

        apiRequestJson<PaginationResponse<TestimonialShortDTO>>("v3/testimonial", Method.GET, params, null)
            .then(dtos => setTestimonialShorts(dtos))
            .catch(() => setTestimonialShorts(null))

    }, [selectedProfession, start, props.size])

    return (
        <>
            <Dropdown options={professionOptions}
                      value={selectedProfession}
                      updateValue={setSelectedProfession}/>
            {
                testimonialShorts !== null ? (
                    <>
                        <LayoutGrid desktop={3}
                                    tablet={2}
                                    mobile={1}>
                            {
                                testimonialShorts.value.map((testimonialShort, key) =>
                                    <TestimonialShort testimonialShort={testimonialShort} key={key}/>
                                )
                            }
                        </LayoutGrid>
                        <Pagination
                            setStart={setStart}
                            size={props.size}
                            total={testimonialShorts.total}/>
                    </>

                ) : (
                    <Spinner/>
                )
            }
        </>
    )
}

export default TestimonialOverview;
