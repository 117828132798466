import "./layout-section.style.scss";
import {LayoutSectionProps} from "./layout-section.type";

const LayoutSection = (props: LayoutSectionProps) => {
  return (
      <div className={"layout-section " + props.color}>
        <div className="layout-section__content">
          {props.children}
        </div>
      </div>
  )
}

export default LayoutSection;
