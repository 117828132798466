import {DynamicGridProps} from "./dynamic-grid.type";
import LayoutGrid from "../../layout/grid/layout-grid.component";
import Dynamic from "../dynamic.component";

const DynamicGrid = (props: DynamicGridProps) => {

  return (
      <LayoutGrid desktop={props.dynamicComponent.columnsDesktop}
                  tablet={props.dynamicComponent.columnsTablet}
                  mobile={props.dynamicComponent.columnsMobile}>
        <Dynamic dynamic={props.dynamicComponent.value}/>
      </LayoutGrid>
  )
}

export default DynamicGrid;
