import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {TestimonialDTO} from "../../../dtos/testimonial.dto";
import {apiRequestJson} from "../../../services/api.service";
import {Method} from "../../../enums/api.enum";
import Spinner from "../../../components/base/spinner/spinner.component";
import LayoutArticle from "../../../components/base/layout/article/layout-article.component";
import {dateString} from "../../../services/date.service";
import LayoutSequence from "../../../components/base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../components/base/layout/sequence/layout-sequence.type";
import LayoutSection from "../../../components/base/layout/section/layout-section.component";
import {Color} from "../../../enums/color.enum";
import Dynamic from "../../../components/base/dynamic/dynamic.component";
import {translateProfession} from "../../../enums/profession.enum";

const TestimonialPage = () => {
    const {id} = useParams()
    const [testimonial, setTestimonial] = useState<TestimonialDTO | null>(null)

    useEffect(() => {
        apiRequestJson<TestimonialDTO>("v3/testimonial/" + id, Method.GET, null, null)
            .then(dto => setTestimonial(dto))
            .catch(() => setTestimonial(null));
    }, [id])

    return (
        <>
            {
                testimonial !== null ? (
                    <>
                        <LayoutArticle
                            imageId={testimonial.imageId}
                            title={testimonial.name}
                            subTilte={dateString(testimonial.datePublished)}>
                            <LayoutSequence direction={LayoutSequenceDirection.COLUMN}>
                                <h4>
                                    Lernende*r {translateProfession(testimonial.profession)}, {testimonial.year} Lehrjahr
                                </h4>
                                <h4>Hobbys</h4>
                                <ul>
                                    {
                                        testimonial.hobbies.map((testimonialHobby, key) =>
                                            <li key={key}>{testimonialHobby.title}</li>)
                                    }
                                </ul>
                            </LayoutSequence>
                        </LayoutArticle>
                        <LayoutSection color={Color.BASE_INVERTED}>
                            <h5>{testimonial.description}</h5>
                            <Dynamic id={testimonial.dynamicComponentId}/>
                        </LayoutSection>
                    </>
                ) : (
                    <Spinner/>
                )
            }
        </>
    )
}

export default TestimonialPage
