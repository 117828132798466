import "./toasty.style.scss"
import {useEffect, useState} from "react";
import {toastySubscribe, toastyUnsubscribe} from "../../../services/toasty.service";
import ToastyItem from "./item/toasty-item.component";
import {ToastyType} from "./toasty.type";

const Toasty = () => {
  const [toasties, setToasties] = useState<Array<any>>([]);

  useEffect(() => {
    const toastyNotification = (message: string, type: ToastyType) => {
      let toasty = {
        id: Math.random().toString(16).slice(5),
        message: message,
        type: type
      }

      setToasties(prevState => [...prevState, toasty])

      setTimeout(() => removeToasty(toasty.id), 3000)
    }

    toastySubscribe(toastyNotification)

    return () => toastyUnsubscribe(toastyNotification)
  }, [])

  const removeToasty = (id: string) => {
    setToasties(prevState => prevState.filter(toasty => toasty.id !== id))
  }

  return (
      <div className="toasty">
        {
          toasties.slice(0, 3).map((toasty, key) =>
              <ToastyItem id={toasty.id}
                          message={toasty.message}
                          type={toasty.type}
                          remove={removeToasty}
                          key={key}/>)
        }
      </div>
  )
}

export default Toasty;
