import "./profession-model.style.scss";
import {ProfessionModelProps} from "./profession-model.type";
import IdImage from "../../../base/id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";
import {translateProfessionModel} from "../../../../enums/profession.enum";
import {Link} from "react-router-dom";
import IconText from "../../../base/icon/text/icon-text.component";
import LayoutSequence from "../../../base/layout/sequence/layout-sequence.component";
import {LayoutSequenceDirection} from "../../../base/layout/sequence/layout-sequence.type";

const ProfessionModel = (props: ProfessionModelProps) => {
  return (
      <Link to={props.model.professionModel}>
        <div className="profession-model">
          <div className="profession-model__image">
            <IdImage id={props.model.imageId} size={ImageSize.LANDSCAPE_MEDIUM}/>
          </div>
          <div className="profession-model__body">
            <h2>{translateProfessionModel(props.model.professionModel)}</h2>
            <p>{props.model.description}</p>
            <LayoutSequence direction={LayoutSequenceDirection.COLUMN}>
              <IconText
                  icon="schedule"
                  text={props.model.duration + (props.model.duration === 1 ? " Jahr" : " Jahre")}
              />
              {props.model.daysSchool > 0 &&
                <IconText
                    icon="school"
                    text={props.model.daysSchool + (props.model.daysSchool === 1 ? " Tag Schule" : " Tage Schule")}
                />
              }
              <IconText
                  icon="work"
                  text={props.model.daysCompany + (props.model.daysCompany === 1 ? " Tag Arbeit" : " Tage Arbeit")}
              />
              <IconText
                  icon="check_circle"
                  text={props.model.openPositions + (props.model.openPositions === 1 ? " Offene Stelle" : " Offene Stellen")}
              />
            </LayoutSequence>
          </div>
        </div>
      </Link>
  )
}

export default ProfessionModel;
