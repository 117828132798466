import {DropdownButtonProps} from "./dropdown-button.type";
import "./dropdown-button.styles.scss";
import Icon from "../../icon/icon.component";
import {Size} from "../../../../enums/size.enum";

const DropdownButton = (props: DropdownButtonProps) => {
  return (
      <div className={"dropdown-button " + (props.visible ? "visible" : "")}
           onClick={() => props.setVisible(!props.visible)}>
        <h6>{props.value}</h6>
        <Icon name={props.visible ? "expand_less" : "expand_more"}
              size={Size.M}/>
      </div>
  )
}

export default DropdownButton;
