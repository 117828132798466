import "./layout-article.style.scss";
import {LayoutArticleProps} from "./layout-article.type";
import IdImage from "../../id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";

const LayoutArticle = (props: LayoutArticleProps) => {
  return (
      <div className="layout-article">
        <div className="layout-article__image">
          <IdImage id={props.imageId} size={ImageSize.LANDSCAPE_MEDIUM}/>
        </div>
        <div className="layout-article__head">
          <div className="layout-article__head-content">
            <h1>{props.title}</h1>
            <h2>{props.subTilte}</h2>
          </div>
          <div className="layout-article__head-image">
            <IdImage id={props.imageId} size={ImageSize.LANDSCAPE_SMALL}/>
          </div>
        </div>
        <div className="layout-article__body">
          <div className="layout-article__body-content">
            <div className="layout-article__body-content-half">
              {props.children}
            </div>
          </div>
        </div>
      </div>
  )
}

export default LayoutArticle;
