import {DynamicTextProps} from "./dynamic-text.type";
import {DynamicComponentText} from "../../../../enums/dynamic-component.enum";

const DynamicText = (props: DynamicTextProps) => {

  switch (props.dynamicComponent.type) {
    case DynamicComponentText.H1:
      return <h1>{props.dynamicComponent.value}</h1>;
    case DynamicComponentText.H2:
      return <h2>{props.dynamicComponent.value}</h2>;
    case DynamicComponentText.H3:
      return <h3>{props.dynamicComponent.value}</h3>;
    case DynamicComponentText.H4:
      return <h4>{props.dynamicComponent.value}</h4>;
    case DynamicComponentText.H5:
      return <h5>{props.dynamicComponent.value}</h5>;
    case DynamicComponentText.H6:
      return <h6>{props.dynamicComponent.value}</h6>;
    case DynamicComponentText.P:
      return <p>{props.dynamicComponent.value}</p>;
    case DynamicComponentText.SMALL:
      return <small>{props.dynamicComponent.value}</small>;
    case DynamicComponentText.BLOCKQUOTE:
      return <blockquote>{props.dynamicComponent.value}</blockquote>;
    case DynamicComponentText.PRE:
      return <pre>{props.dynamicComponent.value}</pre>;
  }
}

export default DynamicText
