import {TestimonialShortProps} from "./testimonial-short.type";
import {Link} from "react-router-dom";
import IdImage from "../../../base/id-image/id-image.component";
import {ImageSize} from "../../../../enums/image-size.enum";
import {translateProfession} from "../../../../enums/profession.enum";
import "./testimonial-short.style.scss";

const TestimonialShort = (props: TestimonialShortProps) => {

  return (
      <>
        <Link to={"/ueber-uns/testimonials/" + props.testimonialShort.id}>
          <div className="testimonial-short">
            <div className="testimonial-short__head">
              <IdImage id={props.testimonialShort.imageId}
                       size={ImageSize.LANDSCAPE_SMALL}/>
            </div>
            <div className="testimonial-short__body">
              <p>{props.testimonialShort.description}</p>
              <h4>{props.testimonialShort.name}</h4>
              <small>Lernende*r {translateProfession(props.testimonialShort.profession)}</small>
            </div>
          </div>
        </Link>
      </>
  )
}

export default TestimonialShort;
