import LayoutSection from "../../components/base/layout/section/layout-section.component";
import {Color} from "../../enums/color.enum";
import LayoutSummary from "../../components/base/layout/summary/layout-summary.component";
import {Profession, translateProfession} from "../../enums/profession.enum";
import ProfessionSummary from "../../components/complex/profession/summary/profession-summary.component";
import imageME from "../../images/profession/ME.jpg";
import imageIA from "../../images/profession/IA.jpg";
import imageIP from "../../images/profession/IP.jpg";
import imageIF from "../../images/profession/IF.jpg";
import imageEDB from "../../images/profession/EDB.jpg";
import imageLO from "../../images/profession/LO.jpg";
import imagePM from "../../images/profession/PM.jpg";
import imageKF from "../../images/profession/KF.jpg";
import imageFK from "../../images/profession/FK.jpg";
import {overviewCompetec} from "../../services/data.service";
import PromotionPreview from "../../components/complex/promotion/promotion.component";
import TestimonialPreview from "../../components/complex/testimonial/preview/testimonial-preview.component";
import AboutUsCompetec from "../../components/complex/about-us/competec/about-us-competec.component";
import BlogOverview from "../../components/complex/blog/overview/blog-overview.component";
import EventOverview from "../../components/complex/event/overview/event-overview.component";

const HomePage = () => {

    return (
        <>
            <LayoutSection color={Color.BASE_INVERTED}>
                <PromotionPreview/>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h1>Ausbildungen bei BRACK.CH</h1>
                <LayoutSummary elements={[
                    {
                        title: translateProfession(Profession.ME),
                        children: <ProfessionSummary profession={Profession.ME}
                                                     image={imageME}/>
                    },
                    {
                        title: translateProfession(Profession.IA),
                        children: <ProfessionSummary profession={Profession.IA}
                                                     image={imageIA}/>
                    },
                    {
                        title: translateProfession(Profession.IP),
                        children: <ProfessionSummary profession={Profession.IP}
                                                     image={imageIP}/>
                    },
                    {
                        title: translateProfession(Profession.IF),
                        children: <ProfessionSummary profession={Profession.IF}
                                                     image={imageIF}/>
                    },
                    {
                        title: translateProfession(Profession.EDB),
                        children: <ProfessionSummary profession={Profession.EDB}
                                                     image={imageEDB}/>
                    },
                    {
                        title: translateProfession(Profession.LO),
                        children: <ProfessionSummary profession={Profession.LO}
                                                     image={imageLO}/>
                    },
                    {
                        title: translateProfession(Profession.PM),
                        children: <ProfessionSummary profession={Profession.PM}
                                                     image={imagePM}/>
                    },
                    {
                        title: translateProfession(Profession.KF),
                        children: <ProfessionSummary profession={Profession.KF}
                                                     image={imageKF}/>
                    },
                    {
                        title: translateProfession(Profession.FK),
                        children: <ProfessionSummary profession={Profession.FK}
                                                     image={imageFK}/>
                    }
                ]} color={Color.BASE}/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Wer sind wir</h1>
                <AboutUsCompetec/>
            </LayoutSection>

            <LayoutSection color={Color.BASE}>
                <h1>Facts</h1>
                <LayoutSummary elements={overviewCompetec} color={Color.BASE}/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Interviews mit unseren Lernenden</h1>
                <TestimonialPreview/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Neuigkeiten</h1>
                <BlogOverview tag={null} start={0} size={3}/>
            </LayoutSection>

            <LayoutSection color={Color.BASE_INVERTED}>
                <h1>Nächste Veranstaltungen</h1>
                <EventOverview profession={null} start={0} size={2} eventType={null}/>
            </LayoutSection>
        </>
    )
}

export default HomePage;
