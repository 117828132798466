import {CarouselItemProps} from "./carousel-item.type";
import "./carousel-item.styles.scss";

const CarouselItem = (props: CarouselItemProps) => {
  return (
      <>
        <div className={"carousel-item " + props.active}>
          {props.children}
        </div>
      </>
  )
}

export default CarouselItem;
