import {TableProps} from "./table.type";
import "./table.styles.scss";
import React from "react";

const Table = (props: TableProps) => {

  return (
      <>
        <div className="table">
          <div className="table__header" style={{"--table-columns": props.header.length} as React.CSSProperties}>
            {
              props.header.map((item, key) => <p key={key}>{item}</p>)
            }
          </div>
          <div className="table__body">
            {props.items.map((row, key) => (
                <div className="table__row"
                     style={{"--table-columns": props.header.length} as React.CSSProperties}
                     key={key}>
                  {
                    row.map((item, key) => <p key={key}>{item}</p>)
                  }
                </div>
            ))}
          </div>
        </div>
      </>
  )
}

export default Table;
