import "./icon-button.styles.scss";
import Icon from "../icon.component";
import {IconButtonProps} from "./icon-button.type";

const IconButton = (props: IconButtonProps) => {
  return (
      <>
        <div className={"icon-button " + props.size + " " + props.color}
             onClick={props.action}>
          <Icon name={props.name}
                size={props.size}/>
        </div>
      </>
  );
}

export default IconButton;
